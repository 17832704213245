.menuItemContainer{     /* Just to make room for divs beside each other to have margins. The same logic as spcing gallery photos*/
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 10vh;
}

.menuItem{   /* column inside the item for the description to stay above the extra info */
    margin: 20px 0px;
    height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;   /*Just put start because it won't make diff for description and pic because they occupy all but will let the extra detais at the bottom be on the left */

}

.menuItemDetails{  /*row for the image to be beside the description*/
    display: flex;
    justify-content: center;
    height: 10vh;
}

.menuItemTitleAndDescription{

    color: #FF7022;
    font-size: 120px;

    height: 10vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.menuDescription{
    color: #7D390C;
    font-size: 100px;
}


.menuItemPrice img{
    width: 80px;
    height: 80px;
}

.menuItemPrice span{
    display: block;
    position:relative;
    top: -56px;
    left: 14px;
    color: #fff;
    font-size: 18px;
    
}

.extraInfoOrganiser{
    margin-top: 2vh;
    display: flex;
}






/*-------------------------------------------------------------------------------------------------------------------------------------------------*/

@media (min-width: 10px) and (max-width: 3900px){
    .menuItemContainer{ 
        width: 80vw;
        margin-top: 140px;
        height: 20vh;
    }

    .menuItem{   /* column inside the item for the description to stay above the extra info */
        width: 80vw;
        height: inherit;
    }


    .menuItemTitleAndDescription{

        color: #FF7022;
        font-size: 20px;
        height: 10vh;
        width: 50vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }

    .menuDescription{
        color: #7D390C;
        font-size: 20px;
    }

    .menuItemPrice span{
        
        width: 16vw;
    }

    .extraInfoOrganiser{
        flex-direction: column;
    }




}

@media (min-width: 10px) and (max-width: 3900px){

    .menuItemContainer{ 
        width: 80vw;
        margin-top: 140px;
        height: 36vh;
    }

    .menuItemPrice span{
        display: block;
        position:relative;
        top: -56px;
        left: 14px;
        color: #fff;
        font-size: 18px;
        width: 60px;
        
    }
}



