

.aboutDisplay{
    max-width: 1000px;
    margin: auto;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offrImgContainer{
    width: 40vw;
    display: flex;
    justify-content: center;
}

.offrImgItem{
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: inherit;
}

.offrParagraphContainer{
    width: 80vw;
    display: flex;
    justify-content: center;
    margin-bottom: 10vh;
}

.offrParagraph{
    color: #C43C52;
    font-weight: bold;
    font-size: 100px;
    line-height: 150px;
}

.greenText{
    color: #9FB876;
    font-size: 22px;
}

.phNumber{
    font-size: 26px;
    font-weight:bold;
    color: #c70928;
}



@media (min-width:10px) and (max-width: 3900px){

    

    .aboutDisplay{
        max-width: 1000px;
        margin: auto;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .offrImgContainer{
        width: 40vw;
        display: flex;
        justify-content: center;
    }

    .offrImgItem{
        margin-top: 10vh;
        margin-bottom: 10vh;
        width: inherit;
    }

    .offrParagraphContainer{
        width: 80vw;
        display: flex;
        justify-content: center;
        margin-bottom: 10vh;
    }

    .offrParagraph{
        color: #C43C52;
        font-weight: bold;
        font-size: 30px;
        line-height: 50px;
    }

    .greenText{
        color: #9FB876;
        font-size: 30px;
    }

    .phNumber{
        font-size: 30px;
        font-weight:bold;
        color: #c70928;
    }

}


@media (min-width:10px) and (max-width: 490px){
    .offrImgContainer{
        width: 86vw;
        display: flex;
        justify-content: center;
    }
    
}
