.menuItemContainer{ /* Just to make room for divs beside each other to have margins. The same olgic as spcing gallery photos*/
    width: 18vw;
    display: flex;
    justify-content: center;
    margin-left: 2vw;

}

.menuItem{   /* column inside the item for the description to stay above the extra info */
    
    margin: 2vh 0vw;
    width: 18vw;
    height: 10vh;
    display: flex;
    flex-direction: column;
    /*align-items: start;   Just put start because it won't make diff for description and pic because they occupy all but will let the extra detais at the bottom be on the left */

}

.menuItemDetails{ /*row for the image to be beside the description*/
    display: flex;
    height: 12vh;
    margin-right: 2vw;
}


.menuItemTitleAndDescriptionSides{

    color: #FF7022;
    font-size: 80px;
    height: 10vh;
    width: 14vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 20px;

}

.menuItemTitleAndDescriptionSides p{
    

}

.menuItemTitleAndDescriptionSides div{


}




.menuItemPriceSides{

width: 4vw;

}

.menuItemPriceSides img{
    height: 280px;
    width: 280px;
    margin-top: 1vh;   
}

.menuItemPriceSides span{
    display: block;
    position:relative;
    top: -5vh;
    left: 0.6vw;
    color: #fff;
    font-size: 64px;
    width: 3vw;
    text-indent: 0%;
}

.extraInfoOrganiser{
    display: flex;
}





.menuDescription{
    
    color: #7D390C;
    font-size: 14px;

}


@media (min-width:10px) and (max-width: 3900px){
    .menuItemContainer{ /* Just to make room for divs beside each other to have margins. The same olgic as spcing gallery photos*/
        width: 300px;
        display: flex;
        justify-content: center;
    
    }
    
    .menuItem{   /* column inside the item for the description to stay above the extra info */
        
        margin: 10px 0px;
        width: 280px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: start;   /*Just put start because it won't make diff for description and pic because they occupy all but will let the extra detais at the bottom be on the left */
    
    
    }
    
    .menuItemDetails{ /*row for the image to be beside the description*/
        display: flex;
        justify-content: center;
        height: 120px;
    }
    
    
    .menuItemTitleAndDescriptionSides{
    
        color: #FF7022;
        font-size: 20px;
        max-width: 200px;
        height: 80px;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        margin-left: 20px;
    
    
    }
    
    .menuItemTitleAndDescriptionSides p{
        
    
    }
    
    .menuItemTitleAndDescriptionSides div{
    
    
    }
    
    .menuItemPriceSides img{
        height: 80px;
        width: 80px;
        margin-right: 20px;   
    }
    
    .extraInfoOrganiser{
        display: flex;
    }
     
    .menuDescription{
        
        color: #7D390C;
        font-size: 14px;
    
    }

    .menuItemPriceSides{
        min-width: 6vw;
    }

    .menuItemPriceSides span{
        min-width: 60px;
        display: block;
        position:relative;
        top: -56px;
        left: 14px;
        color: #fff;
        font-size: 18px;
        
    }
    
    
    

}


@media (min-width:10px) and (max-width: 629px){

    .menuItemPriceSides{
        max-width: 80vw;
    }

    .menuItemPriceSides span{
        min-width: 60px;
        width: 14vw;
        display: block;
        position:relative;
        top: -56px;
        left: 14px;
        color: #fff;
        font-size: 18px;
    }


}