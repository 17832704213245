.formLayout{

    width: 60vw;
    padding-top: 4vh;
    margin: auto;

}



.forms label{
    display: block;
    margin: 4vh 0vw;
    font-size: 72px;
    color: #FF4E00;

}

.forms input{
    height: 6vh;
    width: 10vw;
}

.forms input,
.forms textarea{
    border: 0.4vh solid #FF4E00;
    outline: 0;
    color: #FF4E00;
    padding: 10px;
    font-size: 64px;
    width: 40vw;

    
}

.forms textarea{
    resize: none;
    height: 18vh;

}

.forms button{
    background-color: #E07154;
    outline: 0;
    border: 0;
    color: #fff;
    font-size: 64px;
    font-weight: bold;
    height: 6vh;
    width: 9vw;
    margin: auto;
    margin-top: 4vh;

}

button:hover{
    background-color: #FF4E00;
}