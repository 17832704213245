

.aboutDisplay{
    max-width: 1000px;
    margin: auto;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.abImgContainer{
    width: 40vw;
    display: flex;
    justify-content: center;
}

.abImgItem{
    margin-top: 10vh;
    margin-bottom: 100px;
    width: inherit;
}

.abParagraphContainer{
    margin-top: 10vh;
    width: 90vw;
    display: flex;
    justify-content: center;

}

.abParagraph{
    margin-bottom: 10vh;
    color: #C43C52;
    font-weight: bold;
    font-size: 100px;
    line-height: 150px;
}

.greenText{
    color: #9FB876;
    font-size: 100px;
    text-decoration: none;
}

.phNumber{
    font-size: 100px;
    font-weight:bold;
    color: #c70928;
}


/*-------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

@media (min-width:10px) and (max-width: 3900px){
    

    .aboutDisplay{
        max-width: 1000px;
        margin: auto;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .abImgContainer{
        margin-top: 10vh;
        width: 60vw;
        display: flex;
        justify-content: center;
    }

    .abImgItem{
        margin-top: 10vh;
        margin-bottom: 32px;
        width: inherit;
    }

    .abParagraphContainer{
        margin-top: 10vh;
        width: 60vw;
        display: flex;
        justify-content: center;

    }

    .abParagraphOrange{
        margin-top: 10vh;
        margin-bottom: 10vh;
        color: #FF7022;
        font-weight: bold;
        font-size: 30px;
        line-height: 50px;
        width: 70vw;
    }

    .greenText{
        color: #9FB876;
        font-size: 30px;
        text-decoration: none;
    }

    .phNumber{
        font-size: 20px;
        font-weight:bold;
        color: #c70928;
    }
}

@media (min-width:10px) and (max-width: 490px){

    .abImgContainer{
        width: 86vw;
        display: flex;
        justify-content: center;
    }
    
}